import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Button } from "./shadcnui/ui/button";
import { Label } from "./shadcnui/ui/label";
import { Textarea } from "./shadcnui/ui/textarea";
import axios from "axios";

const PRODUCTION = true;
const BACKEND_URL = PRODUCTION ? "http://159.65.193.12:4000" : "http://localhost:4000";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");

  useEffect(() => {
    const storedPassword = Cookies.get("authPassword");
    if (storedPassword === "R3formToTheMoon123") {
      setIsAuthenticated(true);
    }
  }, []);

  function handlePasswordSubmit() {
    if (passwordInput === "R3formToTheMoon123") {
      Cookies.set("authPassword", passwordInput, { expires: 1 }); // Set cookie to expire in 1 day
      setIsAuthenticated(true);
    } else {
      alert("Incorrect password, please try again.");
    }
  }

  if (!isAuthenticated) {
    return (
      <div className="App">
        <div className="p-8 max-w-[400px] mx-auto text-center">
          <h3 className="font-bold mb-4">Enter Password</h3>
          <input
            type="password"
            value={passwordInput}
            onChange={(e) => setPasswordInput(e.target.value)}
            placeholder="Enter password"
            className="border p-2 w-full mb-4"
          />
          <Button onClick={handlePasswordSubmit}>Submit</Button>
        </div>
      </div>
    );
  }

  return (
    <main className="App">
      <div className="p-8">
        <h3 className="font-bold">Terminal of lies interface</h3>
        <div className="mt-4">
          <Label>
            Amount of posts/day (max): <b>50</b>
          </Label>
        </div>
        <Divider />
        <CurrentContext />
        <SetNewContext />
        <Divider />
        <SendNewMessage />
        <Divider />
        <StartStopInterval />
        <Divider />
        <StartStopNewsInterval />
      </div>
    </main>
  );
}

export default App;

function Divider() {
  return <div className="border-t border-t-gray-200 mb-4 mt-5 max-w-[600px]"></div>;
}

function CurrentContext() {
  const [currentContext, setCurrentContext] = useState<string | null>(null);

  useEffect(() => {
    getCurrentContext(setCurrentContext);
  }, []);

  return (
    <div className="max-w-[600px] mt-2">
      <Label>Current main context:</Label>
      <p className="text-xs line-clamp-5 text-gray-500">
        {currentContext === null ? "Fetching..." : currentContext || "No main context set"}
      </p>
    </div>
  );
}

async function getCurrentContext(setCurrentContext: Function) {
  try {
    const response = await axios.get(BACKEND_URL + "/get-current-context");
    if (!response?.data) return;
    setCurrentContext(response.data.currentContext);
  } catch (error) {
    setCurrentContext("Unable to fetch context");
    console.log("Error fetching current context:", error);
  }
}

function SetNewContext() {
  const [contextInput, setContextInput] = useState<string>();

  async function submitContext() {
    try {
      const response = await axios.post(BACKEND_URL + "/set-context", {
        context: contextInput,
      });
      console.log(response.data);
      window.location.reload();
    } catch (error) {
      console.error("Error submitting context:", error);
      alert("Failed to submit context");
    }
  }

  return (
    <div className="max-w-[600px] mt-2">
      <Label>Set new main context:</Label>
      <Textarea
        className="mt-1"
        value={contextInput}
        onChange={(e) => setContextInput(e.target.value)}
      ></Textarea>
      <Button className="mt-2" onClick={submitContext}>
        Submit
      </Button>
      <p className="text-xs line-clamp-5 text-gray-400 mt-2">
        All context entries gets saved, you can add multiple contextes before sending a new message
      </p>
      <p className="text-xs line-clamp-5 text-gray-400 mt-1">
        Only the last 10 contextes (including news messages) get used as context for OpenAI
        <br />
        to prevent high OpenAI input token costs.
      </p>
    </div>
  );
}

function SendNewMessage() {
  async function handleSend() {
    const confirmation = window.confirm("Are you sure?");
    if (!confirmation) return;

    try {
      const response = await axios.post(BACKEND_URL + "/force-send");
      alert("Sent!");
      console.log(response.data);
      window.location.reload();
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message");
    }
  }

  return (
    <div className="mt-4">
      <Label>Force send new message</Label>
      <p className="text-gray-400 max-w-[400px] text-sm">
        The backend sends the messages automatically, but you can force a message with this button
      </p>
      <Button variant={"destructive"} className="mt-2" onClick={handleSend}>
        Send
      </Button>
    </div>
  );
}

function StartStopInterval() {
  const [intervalStatus, setIntervalStatus] = useState<boolean | null>(null);

  useEffect(() => {
    getIntervalStatus(setIntervalStatus);
  }, []);

  async function handleStart() {
    try {
      await axios.post(BACKEND_URL + "/start");
      setIntervalStatus(true);
    } catch (error) {
      console.error("Error starting interval:", error);
    }
  }

  async function handleStop() {
    try {
      await axios.post(BACKEND_URL + "/stop");
      setIntervalStatus(false);
    } catch (error) {
      console.error("Error stopping interval:", error);
    }
  }

  return (
    <div className="mt-4">
      <Label>Interval status</Label>
      <p className="text-gray-400 max-w-[400px] text-sm">
        {intervalStatus === null ? "fetching..." : `Running: ${intervalStatus}`}
      </p>

      <div className="flex items-center gap-2 mt-3">
        <Button variant={"outline"} onClick={handleStop}>
          Stop
        </Button>
        <Button onClick={handleStart}>Start</Button>
      </div>
    </div>
  );
}

async function getIntervalStatus(setIntervalStatus: Function) {
  try {
    const response = await axios.get(BACKEND_URL + "/get-interval-status");
    if (!response?.data) return;
    setIntervalStatus(response.data.running.toString());
  } catch (error) {
    setIntervalStatus("Unable to fetch interval status");
    console.log("Error fetching current context:", error);
  }
}

function StartStopNewsInterval() {
  const [intervalStatus, setIntervalStatus] = useState<boolean | null>(null);

  useEffect(() => {
    getIntervalNewsStatus(setIntervalStatus);
  }, []);

  async function handleStart() {
    try {
      await axios.post(BACKEND_URL + "/start-news-checker");
      setIntervalStatus(true);
    } catch (error) {
      console.error("Error starting interval:", error);
    }
  }

  async function handleStop() {
    try {
      await axios.post(BACKEND_URL + "/stop-news-checker");
      setIntervalStatus(false);
    } catch (error) {
      console.error("Error stopping interval:", error);
    }
  }

  return (
    <div className="mt-4">
      <Label>News checker interval status</Label>
      <p className="text-gray-400 max-w-[400px] text-sm">
        Checking all news from cointelegraph.com
      </p>
      {/* <p className="text-gray-400 max-w-[400px] text-sm">
        {intervalStatus === null ? "fetching..." : `Running: ${intervalStatus}`}
      </p> */}

      <p className="text-gray-400 max-w-[400px] text-sm mt-3">Manually disabled</p>

      {/* <div className="flex items-center gap-2 mt-3">
        <Button variant={"outline"} onClick={handleStop}>
          Stop
        </Button>
        <Button onClick={handleStart}>Start</Button>
      </div> */}
    </div>
  );
}

async function getIntervalNewsStatus(setIntervalStatus: Function) {
  try {
    const response = await axios.get(BACKEND_URL + "/get-news-checker-interval-status");
    if (!response?.data) return;
    setIntervalStatus(response.data.running.toString());
  } catch (error) {
    setIntervalStatus("Unable to fetch news-checker interval status");
    console.log("Error fetching current context:", error);
  }
}
